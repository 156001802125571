import $ from "jquery"

export default class MobileMenuManager {
    #menuShown = false
    #phoneBreakpoint = window.matchMedia('(max-width: 520px)')
    static #sharedInstance

    static get shared() {
        if (!this.#sharedInstance) this.#sharedInstance = new MobileMenuManager()
        return this.#sharedInstance
    }

    init() {
        this.#phoneBreakpoint.addEventListener("change", this.#phoneBreakpointChange.bind(this))
        this.#phoneBreakpointChange(this.#phoneBreakpoint)
        window.addEventListener("routechange", this.#routeChanged.bind(this))
        window.addEventListener("menuloaded", this.#menuLoaded.bind(this))
    }

    toggleMenu() {
        if (this.#menuShown) this.#hideMenu()
        else this.#showMenu()
    }

    closeMenu() {
        $("#menu-checkbox").prop("checked", false)
        this.#hideMenu()
    }

    #hideMenu() {
        this.#langSelectVisible(false)
        this.#menuItemsVisible(false)
        this.#shadeVisible(false)
        this.#menuShown = false
    }

    #showMenu() {
        this.#langSelectVisible(true)
        this.#menuItemsVisible(true)
        this.#shadeVisible(true)
        this.#menuShown = true
    }

    #phoneBreakpointChange(bp) {
        if (bp.matches) {
            this.#langSelectVisible(false)
            this.#menuItemsVisible(false)
        } else {
            this.#menuShown = false
            this.#clearComponents()
        }
    }

    #langSelectVisible(flag) {
        if (flag) {
            $("#lang-select").css("visibility", "visible")
            $("#lang-select").addClass("active")
            $("#lang-select").removeClass("idle")
        } else {
            $("#lang-select").css("visibility", "hidden")
            $("#lang-select").addClass("idle")
            $("#lang-select").removeClass("active")
        }
    }

    #menuItemsVisible(flag) {
        if (flag) {
            $("#menu-items").css("visibility", "visible")
            $("#menu-items").addClass("active")
            $("#menu-items").removeClass("idle")
        } else {
            $("#menu-items").css("visibility", "hidden")
            $("#menu-items").addClass("idle")
            $("#menu-items").removeClass("active")
        }
    }

    #shadeVisible(flag) {
        if (flag) {
            $("#menu-shade").css("visibility", "visible")
            $("#menu-shade").addClass("active")
            $("#menu-shade").removeClass("idle")
        } else {
            $("#menu-shade").css("visibility", "hidden")
            $("#menu-shade").addClass("idle")
            $("#menu-shade").removeClass("active")
        }
    }


    #clearComponents() {
        $("#lang-select").removeAttr('style')
        $("#lang-select").addClass("idle")
        $("#lang-select").removeClass("active")

        $("#menu-items").removeAttr('style')
        $("#menu-items").addClass("idle")
        $("#menu-items").removeClass("active")

        $("#menu-shade").removeAttr('style')
        $("#menu-shade").addClass("idle")
        $("#menu-shade").removeClass("active")

        $("#menu-checkbox").prop("checked", false)
    }

    #routeChanged(e) {
        if (this.#menuShown && e.detail.reason === "path") { 
            this.closeMenu()
        }
    }

    #menuLoaded() {
        if (this.#menuShown) {
            this.#showMenu()
            $("#menu-checkbox").prop("checked", true)
        }
    }
}