export const pages = {
	"/en/404.html": import("../pages/en/404.html"),
	"/en/about.html": import("../pages/en/about.html"),
	"/en/contact.html": import("../pages/en/contact.html"),
	"/en/home.html": import("../pages/en/home.html"),
	"/en/home.old.html": import("../pages/en/home.old.html"),
	"/en/menu.html": import("../pages/en/menu.html"),
	"/en/projects.old.html": import("../pages/en/projects.old.html"),
	"/en/team.html": import("../pages/en/team.html"),
	"/fr/404.html": import("../pages/fr/404.html"),
	"/fr/about.html": import("../pages/fr/about.html"),
	"/fr/contact.html": import("../pages/fr/contact.html"),
	"/fr/home.html": import("../pages/fr/home.html"),
	"/fr/menu.html": import("../pages/fr/menu.html"),
	"/fr/projects.html": import("../pages/fr/projects.html"),
	"/fr/team.html": import("../pages/fr/team.html"),
	"/lu/404.html": import("../pages/lu/404.html"),
	"/lu/about.html": import("../pages/lu/about.html"),
	"/lu/contact.html": import("../pages/lu/contact.html"),
	"/lu/home.html": import("../pages/lu/home.html"),
	"/lu/menu.html": import("../pages/lu/menu.html"),
	"/lu/projects.html": import("../pages/lu/projects.html"),
	"/lu/team.html": import("../pages/lu/team.html"),
	"/en/projects/CEMC.html": import("../pages/en/projects/CEMC.html"),
	"/en/projects/FR.html": import("../pages/en/projects/FR.html"),
	"/en/projects/LMIH.html": import("../pages/en/projects/LMIH.html"),
	"/en/projects/SDD.html": import("../pages/en/projects/SDD.html"),
	"/en/projects/VL.html": import("../pages/en/projects/VL.html"),
	"/en/projects/WO.html": import("../pages/en/projects/WO.html"),
	"/en/projects/template.html": import("../pages/en/projects/template.html"),
	"/fr/projects/SDD.html": import("../pages/fr/projects/SDD.html"),
	"/lu/projects/SDD.html": import("../pages/lu/projects/SDD.html"),
}