import $ from "jquery"

/**
 * Get the main content container
 *
 * @export
 * @returns { JQuery<HTMLElement> }
 */
export function getContentContainer() {
    return $("#content-container")
}


/**
 * Get the menu container
 * @date 8/30/2023 - 4:26:58 PM
 *
 * @export
 * @returns { JQuery<HTMLElement> }
 */
export function getMenuContainer() {
    return $("#menu")
}


/**
 * Get the current browser language
 *
 * @export
 * @returns { String }
 */
export function getLanguage() {
    if (navigator.language) return navigator.language.replace('_', '-').split('-')[0]
    else return 'en';
}