import $ from "jquery"
import * as utils from "./utils.js"
import Router from "./router.js"
import MobileMenuManager from "./mobileMenuManager.js"
import Particles from "./particles.js"


function setup() {
	Router.shared.init()
	MobileMenuManager.shared.init()
	//const particles = new Particles()
	
	setupLanguage()

	window.addEventListener("routelangchange", onLanguageChangeFromRoute)
	window.addEventListener("menuloaded", onMenuLoaded)
	window.addEventListener("routechange", onRouteChange)

	$("#menu-shade").on("click", closeMenu)
}

function setupLanguage() {
	const lang = utils.getLanguage()
	window.lang = lang
}

function onMenuLoaded(event) {
	$("#lang-select").val(window.lang)
	$("#lang-select").on("change", onLanguageChange)
	$("#menu-checkbox").on("click", toggleMenu)
}

/**
 * @param { CustomEvent } event 
 */
function onLanguageChange(event) {
	const newLang = event.target.value
	window.lang = newLang
	Router.shared.changeLang(newLang)
}

/**
 * @param { CustomEvent } event
 */
function onLanguageChangeFromRoute(event) {
	const newLang = event.detail.lang

	if (window.lang !== newLang) {
		window.lang = newLang
		$("#lang-select").val(lang)
	}
}

function toggleMenu() {
	MobileMenuManager.shared.toggleMenu()
}

function closeMenu() {
	MobileMenuManager.shared.closeMenu()
}

function onRouteChange(event) {
	currentSlideIndex = 0;
    return;
	if (/*event.detail.route === "/en/home" || */
		event.detail.route === "/en/about"
	) {
		document.getElementById("particles-div").style.visibility = "visible";
	}
	else
	{
		document.getElementById("particles-div").style.visibility = "hidden";
	}
}

setup()