import $ from "jquery"
import * as utils from "./utils.js"
import { pages } from "./filelist.js"

const http404Route = "/*/404"
const homeRoute = "/*/home"
const availableLangs = ["en", "lu", "fr"]
const homeShorthand = /^\/(\*|en|fr|lu)\/?$/
const validRoute = /^\/(\*|en|fr|lu)(\/[a-zA-Z0-9]+)+$/

export default class Router {
    static #sharedInstance

    constructor() {
        this.route = ""
        this.loadedMenuLang = ""
        this.lang = ""
    }

    /**
     * @returns { Router }
     */
    static get shared() {
        if (!this.#sharedInstance) this.#sharedInstance = new Router()
        return this.#sharedInstance
    }
    
    init() {
        window.addEventListener("load", this.#handleHashChange.bind(this))
        window.addEventListener("hashchange", this.#handleHashChange.bind(this))
    }

    navigateToHome() {
        this.navigateTo(homeRoute)
    }

    /**
     * @param { String } lang
     */
    changeLang(lang) {
        if (this.lang !== lang) {
            const newRoute = routeForLang(this.route, lang)
            window.location.hash = newRoute
        }
    }
    
    async navigateTo(route, notFound = false) {
        try {
            const rRoute = resolveRoute(route)
            if (this.route === rRoute) return
            
            try {
                const content = await load(rRoute)
                await this.#loadMenuIfNeededFor(langFromRoute(rRoute))
                setContentContainerContent(content)
                
                this.route = rRoute
                this.#replaceHashAndNotify(false, !notFound)
            } catch {
                try {
                    const fRoute = failsafePath(rRoute)
                    const content = await load(fRoute)
                    await this.#loadMenuIfNeededFor(langFromRoute(fRoute))
                    setContentContainerContent(content)
                    
                    this.route = fRoute
                    this.#replaceHashAndNotify(true, notFound)
                } catch {
                    this.noRoute()
                }
            }
        } catch {
            this.noRoute()
        }
    }
    
    async noRoute() {
        this.navigateTo(http404Route, true)
    }

    async #loadMenuIfNeededFor(lang) {
        if (this.loadedMenuLang !== lang) {
            const rPath = `/${lang}/menu`
    
            try {
                const content = await load(rPath)
                utils.getMenuContainer().html(content)
                this.loadedMenuLang = window.lang
            } catch {
                const fPath = failsafePath(rPath)
                const content = await load(fPath)
                utils.getMenuContainer().html(content)
                this.loadedMenuLang = "en"
            }

            const langEvent = new CustomEvent("menuloaded", { cancelable: false })
            window.dispatchEvent(langEvent)
        }
    }

    #replaceHashAndNotify(fallback, succes) {
        this.#replaceHash(this.route)

        const newLang = langFromRoute(this.route)
        const langChanged = this.lang !== newLang
        if (langChanged) this.lang = newLang 

        const routeEvent = new CustomEvent("routechange", {
            detail: {
                route: this.route,
                langFallback: fallback,
                success: succes,
                reason: langChanged ? "lang" : "path"
            },
            cancelable: false
        })
        window.dispatchEvent(routeEvent)

        if (langChanged) {
            const langEvent = new CustomEvent("routelangchange", {
                detail: { lang: newLang },
                cancelable: false
            })

            window.dispatchEvent(langEvent)
        }
    }

    #replaceHash(newHash) {
        const origin = window.location.origin
        window.location.replace(`${origin}/#${newHash}`)
    }

    /**
     * @param { Event } event 
     */
    #handleHashChange(e) {
        const pathName = window.location.pathname
        if (pathName.length > 0 && pathName !== "/") return this.noRoute()

        const hash = window.location.hash
        if (hash === "" || hash === "/") this.navigateToHome()
        else this.navigateTo(hash.replace("#", ""))
    }
}  

async function load(path) { 
    const page = pages[`${path}.html`]
    if (page) return await page
    else throw Error()
}

function resolveRoute(path) { 
    if (path === "/" || homeShorthand.test(path)) return resolveRoute(homeRoute)
    if (!validRoute.test(path)) throw Error()
    return path.replace("*", window.lang)
}

function failsafePath(path) {
    let comps = path.split("/")
    comps[1] = "en"
    return comps.join("/")
}

function langFromRoute(route) {
    let comps = route.split("/")
    return comps[1]
}

function routeForLang(route, lang) {
    let comps = route.split("/")
    comps[1] = lang
    return comps.join("/")
}

function setContentContainerContent(content) {
    const jContent = $(content)
    const menu = jContent.data("menu")
    const container = utils.getContentContainer()
    const menuContainer = utils.getMenuContainer()

    if (menu === "hidden") { 
        menuContainer.hide()
    } else {
        menuContainer.show()
        $(".menu-item-selected").removeClass("menu-item-selected")
        $(`#menu-${menu}`).addClass("menu-item-selected")
    }

    container.html(content)
    // container.hide(200, () => {
    //     container.html(content)
    //     container.show(300)
    // })
}